<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#30BF2D" />
      <path d="M8 12L11 15L16 9" stroke="white" stroke-linecap="round" />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "GreenCheckIcon",
};
</script>
