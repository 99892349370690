var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-open__header"},[_c('span',{staticClass:"module-open__title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"module-open__nav"},[(_vm.havePrev)?_c('button',{staticClass:"btn btn--small btn--gray-outline btn--gray-outline--thin",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('change', {
          type: 'lesson',
          direction: -1,
        })}}},[_vm._v("\n      Предыдущий урок\n    ")]):_vm._e(),(_vm.haveNext)?_c('button',{staticClass:"btn btn--small btn--gray-outline btn--gray-outline--thin",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('change', {
          type: 'lesson',
          direction: 1,
        })}}},[_vm._v("\n      Следующий урок\n    ")]):_vm._e(),(_vm.showTypeToggle)?_c('button',{staticClass:"btn btn--small btn--main",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('change', {
          type: 'type',
        })}}},[(_vm.passingTest)?[_vm._v("Вернуться к заданию")]:[_vm._v("Пройти тест")]],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }