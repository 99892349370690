<template>
  <div class="check-list">
    <label
      class="check-list__item"
      :class="{ 'check-list__item--image': !!item.image }"
      v-for="(item, i) in list"
      :key="i"
    >
      <CheckboxComponent :checked="isChecked(item)" @change="$emit('change', item)" />
      <span class="check-list__item-content">
        <img class="check-list__item-image" :src="item.image" v-if="item.image" :alt="item.title" />
        <span class="check-list__item-title">{{ item.title }}</span>
      </span>
    </label>
  </div>
</template>

<script>
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";

export default {
  name: "CourseCheckboxList",
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    isChecked(item) {
      return !!this.value.filter((i) => JSON.stringify(i) === JSON.stringify(item)).length;
    },
  },
  components: { CheckboxComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/check-list.styl"
</style>
