<template>
  <main class="main module-open" v-if="module">
    <CourseAsideComponent :list="asideList" :active="activeAside" @change="activeAside = $event" />
    <Transition mode="out-in" name="page" v-if="canRender">
      <Component
        :is="activePage"
        :lesson="activeAside"
        :passingTest="passingTest"
        :showTypeToggle="activeAside.showTypeToggle"
        :haveNext="!!haveNext"
        :havePrev="!!havePrev"
        @change="handleChangeEvent"
      />
    </Transition>
  </main>
  <PageNotFound v-else />
</template>

<script>
import CourseAsideComponent from "../components/CourseAsideComponent.vue";
import CourseVideoPage from "./components/CourseVideoPage.vue";
import CourseTestPage from "./components/CourseTestPage.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import MY_MODULE from "gql/auth/my_module.graphql";
import plural from "plural-ru";

export default {
  async asyncData({ route, res, store, apollo, router }) {
    if (!route.params && !route.params.module) {
      res.status(404);
    } else {
      await apollo.defaultClient
        .query({
          query: MY_MODULE,
          variables: {
            id: parseInt(route.params.module),
          },
          context: {
            headers: {
              Authorization: "Bearer " + store.state.cookies["apollo-token"],
            },
          },
        })
        .then(({ data }) => {
          if (data.my_module) {
            store.state.tmp.module = data.my_module;

            // Форматирование под сайдбар
            store.state.tmp.asideList = data.my_module.lessons.map((lesson) => {
              lesson.subtitle = lesson.video && lesson.video.video ? lesson.video.video.duration : "";
              lesson.passingTest = false;
              lesson.showTypeToggle = true;
              lesson.check = !!lesson.auth_passed_lesson;
              return lesson;
            });

            // Добавление пункта "Проверочный тест по модулю" в сайдбар
            let lastTest = data.my_module;
            lastTest.id = -1;
            lastTest.title = "Проверочный тест по модулю";
            lastTest.subtitle = "";
            if (lastTest.tests) {
              lastTest.subtitle = plural(lastTest.tests.length, "%d вопрос", "%d вопроса", "%d вопросов");
            }
            lastTest.passingTest = true; // Проходит тест или нет (показывать шаблон тестов или задания(видео))
            lastTest.check = false; // Тест пройден или нет. для галочки
            lastTest.showTypeToggle = false; // Показывать переключатель на тест
            store.state.tmp.asideList.push(lastTest);

            // Если в роуте не указан урок то по умолчанию берется 1й
            if (store.state.tmp.asideList[0]) {
              let foundAsideItem = store.state.tmp.asideList.find((lesson) => {
                return lesson.id === parseInt(route.params.lesson);
              });
              store.state.tmp.activeAside = foundAsideItem || store.state.tmp.asideList[0];
            }
          }
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors[0] && graphQLErrors[0].message === "Unauthenticated.") {
            router.push({
              name: "home",
            });
          } else {
            store.state.tmp.haveError = true;
          }
        });
    }
  },
  name: "AccountEducation",
  data() {
    return {
      module: {},
      activeAside: {},
      asideList: [],
      canRender: false,
      passingTest: false,
    };
  },
  computed: {
    asideIndex() {
      return this.asideList.findIndex((lesson) => {
        return lesson.id === this.activeAside.id;
      });
    },
    haveNext() {
      return this.asideList[this.asideIndex + 1];
    },
    havePrev() {
      return this.asideList[this.asideIndex - 1];
    },
    activePage() {
      return this.passingTest ? CourseTestPage : CourseVideoPage;
    },
  },
  mounted() {
    this.module = this.$store.state.tmp.module;
    this.activeAside = this.$store.state.tmp.activeAside;
    this.passingTest = JSON.parse(JSON.stringify(this.activeAside.passingTest));
    this.asideList = this.$store.state.tmp.asideList;
    this.canRender = true;
    if (this.$store.state.tmp.haveError) {
      this.$notify({
        title: "Ошибка",
        text: "Во время загрузки данных произошла ошибка, попробуйте перезагрузить страницу",
        duration: 8000,
        speed: 200,
        type: "error",
      });
    }
  },
  methods: {
    handleChangeEvent(event) {
      switch (event.type) {
        case "lesson":
          this.$router.push({
            name: "account_education_open",
            params: {
              module: this.$route.params.module,
              lesson: event.direction > 0 ? this.haveNext.id : this.havePrev.id,
            },
          });
          break;
        case "type":
          this.passingTest = !this.passingTest;
          break;
      }
    },
  },
  components: { CourseVideoPage, CourseTestPage, LoadingIndicator, CourseAsideComponent, PageNotFound },
};
</script>

<style lang="stylus">
.module-open {
  padding 0
  background var(--white)
  display grid
  grid-template-columns 3fr 8fr 1fr
  grid-gap 30px
  align-items start
  justify-content start
  +below(1440px) {
    grid-template-columns 3fr 9fr
  }
  +below(1220px) {
    grid-template-columns 4fr 8fr
  }
  +below(960px) {
    grid-template-columns 1fr
    .course-aside {
      display none
    }
  }

  &__container {
    display grid
    grid-gap 30px
    width 100%
    padding 15px 0 100px

    > .btn {
      margin-right auto
      margin-top 5px
    }
    +below(1440px) {
      padding-right 32px
    }
    +below(960px) {
      padding 15px 32px 100px
    }
    +below(640px) {
      padding 15px 20px 50px
    }
  }

  &__header {
    display flex
    gap 20px
    justify-content space-between
    align-items center
    +below(768px) {
      flex-direction column-reverse
      align-items flex-start
      justify-content flex-start
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 29px;
    color: var(--black);
  }

  &__nav {
    display flex
    gap 15px
    align-items center
    +below(768px) {
      width 100%
    }
    +below(460px) {
      & > * {
        width 100%
      }
    }
    +below(420px) {
      flex-direction column
    }
  }

  .player {
    max-height 610px
    width 100%
    display flex
    +below(960px) {
      max-height 520px
    }
    +below(768px) {
      max-height 420px
    }
    +below(640px) {
      max-height 340px
    }
    +below(460px) {
      max-height 250px
    }
  }
}
</style>
