<template>
  <div class="module-open__container">
    <CoursePageHeader
      :title="lesson.title"
      :haveNext="haveNext"
      :havePrev="havePrev"
      :passingTest="passingTest"
      :showTypeToggle="showTypeToggle"
      @change="$emit('change', $event)"
    />
    <div class="course-tests">
      <CourseTestComponent :order="i + 1" :key="i" :title="test.title" v-for="(test, i) in testsForm">
        <Component :is="test.component" v-bind="test.props" @change="setValueFromChange($event, test, i)" />
      </CourseTestComponent>
    </div>
    <button type="button" class="btn btn--medium btn--main" @click="submitTest">
      <LoadingIndicator v-if="loading" />
      <template v-else>Получить результаты</template>
    </button>
  </div>
</template>

<script>
import CourseTestComponent from "components/inputs/test/index.vue";
import CourseCheckboxList from "./components/inputs/CourseCheckboxList.vue";
import CourseRadioList from "./components/inputs/CourseRadioList.vue";
import CourseTextareaWrapper from "./components/inputs/CourseTextareaWrapper.vue";
import CourseFileUploadWrapper from "./components/inputs/CourseFileUploadWrapper.vue";
import CoursePageHeader from "./components/CoursePageHeader.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import TAKING_TEST from "gql/mutations/TakingTest.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";

export default {
  name: "CourseTestPage",
  props: {
    havePrev: Boolean,
    haveNext: Boolean,
    showTypeToggle: Boolean,
    passingTest: Boolean,
    lesson: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      testsForm: [],
    };
  },
  computed: {
    types() {
      return this.$store.state._types;
    },
  },
  created() {
    this.testsForm = [];
    this.lesson.tests.forEach((test) => {
      switch (test.type.code) {
        case this.types.CODE_TEST_TEXT_INPUT:
          this.testsForm.push(this.getFormattedTestInput(test));
          break;
        case this.types.CODE_TEST_SELECTING_ONE:
          this.testsForm.push(this.getFormattedTestSelectOne(test));
          break;
        case this.types.CODE_TEST_SELECTING_FEW:
          this.testsForm.push(this.getFormattedTestSelectFew(test));
          break;
        case this.types.CODE_TEST_FILE_UPLOAD:
          this.testsForm.push(this.getFormattedTestUpload(test));
          break;
      }
    });
  },
  methods: {
    submitTest() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: TAKING_TEST,
            variables: {
              input: this.testsForm.map((test) => {
                let answer_id = null;
                let answer = null;
                let documents = null;
                if (test.type === this.types.CODE_TEST_SELECTING_ONE) {
                  answer_id = [test.props.value.id];
                }
                if (test.type === this.types.CODE_TEST_SELECTING_FEW) {
                  answer_id = test.props.value.map((v) => v.id);
                }
                if (test.type === this.types.CODE_TEST_TEXT_INPUT) {
                  answer = test.props.value;
                }
                if (test.type === this.types.CODE_TEST_FILE_UPLOAD) {
                  documents = test.props.value;
                }
                return {
                  test_id: test.id,
                  answer_id: answer_id,
                  answer: answer,
                  documents: documents,
                };
              }),
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.cookies["apollo-token"],
              },
            },
          })
          .then(({ data }) => {
            if (data && data.TakingTest) {
              let points = data.TakingTest.map((test) => test.point).reduce((a, b) => a + b, 0);
              let maxPoints = data.TakingTest.map((test) => test.max_point).reduce((a, b) => a + b, 0);
              let isPassed = false;
              if (points >= maxPoints) {
                isPassed = true;
              }
              let options = {
                type: isPassed ? "success" : "error",
                title: isPassed ? "Тест пройден успешно!" : "Тест не пройден",
                subtitle: `Вы набрали ${points} из ${maxPoints} баллов.`,
                buttonTitle: isPassed ? "Далее" : "Попробовать ещё раз",
                callback: () => {
                  if (isPassed) {
                    this.$emit("change", {
                      type: "lesson",
                      direction: 1,
                    });
                  } else {
                    this.resetForm();
                  }
                },
              };
              this.$store.state._modals.push({
                component: AlertModal,
                options: options,
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.$notify({
              title: "Ошибка",
              text: "Произошла ошибка во время обработки теста",
              duration: 8000,
              speed: 200,
              type: "error",
            });
            this.loading = false;
          });
      }
    },
    resetForm() {
      this.testsForm.forEach((test) => {
        test.props.value = test.props.defaultValue;
      });
    },
    getFormattedTestInput(test) {
      return {
        id: test.id,
        type: this.types.CODE_TEST_TEXT_INPUT,
        title: test.title,
        component: CourseTextareaWrapper,
        props: {
          title: "Введите ваш ответ",
          rows: 10,
          value: null,
          defaultValue: null,
        },
      };
    },
    getFormattedTestSelectOne(test) {
      return {
        id: test.id,
        type: this.types.CODE_TEST_SELECTING_ONE,
        title: test.title,
        component: CourseRadioList,
        props: {
          list: test.test_answers.map((answer) => ({
            id: answer.id,
            title: answer.answer,
          })),
          value: null,
          defaultValue: null,
        },
      };
    },
    getFormattedTestSelectFew(test) {
      return {
        id: test.id,
        type: this.types.CODE_TEST_SELECTING_FEW,
        title: test.title,
        component: CourseCheckboxList,
        props: {
          list: test.test_answers.map((answer) => ({
            id: answer.id,
            title: answer.answer,
          })),
          value: [],
          defaultValue: [],
        },
      };
    },
    getFormattedTestUpload(test) {
      return {
        id: test.id,
        type: this.types.CODE_TEST_FILE_UPLOAD,
        title: test.title,
        component: CourseFileUploadWrapper,
        props: {
          value: [],
          defaultValue: [],
        },
      };
    },
    toggleFromArray(array, item) {
      array = array.map((i) => JSON.stringify(i));
      if (array.indexOf(JSON.stringify(item)) === -1) {
        array.push(JSON.stringify(item));
      } else {
        array.splice(array.indexOf(JSON.stringify(item)), 1);
      }
      return array.map((i) => JSON.parse(i));
    },
    setValueFromChange(event, test, i) {
      switch (test.component) {
        case CourseCheckboxList:
          this.testsForm[i].props.value = this.toggleFromArray(this.testsForm[i].props.value, event);
          break;
        default:
          this.testsForm[i].props.value = event;
          break;
      }
    },
  },
  components: { LoadingIndicator, CoursePageHeader, CourseTestComponent },
};
</script>

<style lang="stylus">
.course-tests {
  display grid
  grid-gap 40px

  .file-upload
  .ipt {
    width 100%
    max-width 520px
  }
}
</style>
