<template>
  <figure class="icon">
    <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1H8" stroke="white" stroke-width="2" />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CheckMarkIcon",
};
</script>
