<template>
  <div class="module-open__container">
    <CoursePageHeader
      :title="lesson.title"
      :haveNext="haveNext"
      :havePrev="havePrev"
      :passingTest="passingTest"
      :showTypeToggle="showTypeToggle"
      @change="$emit('change', $event)"
    />
    <ClientOnly v-if="lesson.video">
      <PlyrWrapper
        :url="lesson.video.url"
        :poster="lesson.head_img | image($store.state._env.MEDIA_ENDPOINT)"
      />
    </ClientOnly>
    <div class="course-tabs">
      <a
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ 'course-tabs__item--active': activeTab === i }"
        @click.prevent="activeTab = i"
        class="course-tabs__item"
      >
        {{ tab.title }}
      </a>
    </div>
    <EditorJSComponent :text="activeTab === 0 ? lesson.text || '' : lesson.homework || ''" />
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import CoursePageHeader from "./components/CoursePageHeader.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "CourseVideoPage",
  props: {
    havePrev: Boolean,
    haveNext: Boolean,
    showTypeToggle: Boolean,
    passingTest: Boolean,
    lesson: {
      type: Object,
    },
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          title: "Описание",
        },
        {
          title: "Домашнее задание",
        },
      ],
    };
  },
  components: {
    EditorJSComponent,
    CoursePageHeader,
    ClientOnly,
    PlyrWrapper: () => import("components/PlyrWrapper.vue"),
  },
};
</script>

<style lang="stylus">
.course-tabs {
  display flex
  align-items flex-start
  justify-content flex-start
  gap 30px
  border-bottom 1px solid var(--border_color)
  +below(768px) {
    gap 20px
  }

  &__item {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 29px;
    color: var(--black);
    padding-bottom 10px
    border-bottom 1px solid transparent
    margin-bottom -1px
    +below(768px) {
      font-size 1em
      line-height 19px
    }

    &--active {
      border-bottom-color var(--main_color)

      &:hover {
        border-bottom-color var(--main_color)
      }
    }
  }
}
</style>
