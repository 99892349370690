<template>
  <div class="module-open__header">
    <span class="module-open__title">{{ title }}</span>
    <div class="module-open__nav">
      <button
        type="button"
        v-if="havePrev"
        class="btn btn--small btn--gray-outline btn--gray-outline--thin"
        @click="
          $emit('change', {
            type: 'lesson',
            direction: -1,
          })
        "
      >
        Предыдущий урок
      </button>
      <button
        type="button"
        v-if="haveNext"
        class="btn btn--small btn--gray-outline btn--gray-outline--thin"
        @click="
          $emit('change', {
            type: 'lesson',
            direction: 1,
          })
        "
      >
        Следующий урок
      </button>
      <button
        type="button"
        v-if="showTypeToggle"
        class="btn btn--small btn--main"
        @click="
          $emit('change', {
            type: 'type',
          })
        "
      >
        <template v-if="passingTest">Вернуться к заданию</template>
        <template v-else>Пройти тест</template>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CoursePageHeader",
  props: {
    havePrev: Boolean,
    haveNext: Boolean,
    showTypeToggle: {
      type: Boolean,
      default() {
        return false;
      },
    },
    passingTest: Boolean,
    title: String,
  },
};
</script>
