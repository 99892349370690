<template>
  <figure class="icon">
    <svg fill="none" height="10" viewBox="0 0 12 10" width="12" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4.11111L4.57143 8L11 1" stroke="white" stroke-width="2" />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CheckMarkIcon",
};
</script>
