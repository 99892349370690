<template>
  <div class="test">
    <span class="test__order">{{ order }}</span>
    <span class="test__title">{{ title }}</span>
    <div class="test__fields">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Number,
    },
    title: {
      type: String,
      required: true,
    },
  },
  name: "CourseTestComponent",
};
</script>

<style lang="stylus">
.test {
  display grid
  grid-gap 20px
  padding-left 65px
  position relative
  max-width 640px
  +below(768px) {
    padding-left 0
    gap 15px
  }

  &__order {
    width 50px
    height 50px
    border-radius 100%
    display flex
    align-items center
    justify-content center
    flex-shrink 0
    font-weight: 500;
    font-size: 1.125em;
    line-height: 22px;
    color: var(--white);
    absolute left top
    background var(--main_color)
    +below(768px) {
      relative left top
    }
  }

  &__title {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 20px;
    color: var(--black);
    padding 15px 0
  }
}
</style>
